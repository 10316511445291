import React from 'react';
import IconFB from './IconFB';
import IconIG from './IconIG';
import Logo from '../images/hairByAryWhite.png';

const Header = () => (
  <div className="hero">
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      data-doc-height="1"
      className="nav w-nav"
    >
      <div className="nav-inner">
        <div className="nav-logo-wrap">
          <a href="/" className="brand w-nav-brand">
            <img
              src={Logo}
              width="285"
              // srcset="images/hairByAryWhite-p-500.png 500w, images/hairByAryWhite.png 782w"
              sizes="285px"
              alt=""
            />
          </a>
        </div>
        <div className="nav-menu-wrap">
          <a href="https://www.facebook.com/HairByAry/">
            <IconFB iconTitle="Facebook Icon" iconColor="white" />
          </a>
          <a href="https://www.instagram.com/aryfcapra/">
            <IconIG iconTitle="Instagram Icon" iconColor="white" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
