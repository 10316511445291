import React, { Component } from 'react';

class Booking extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.async = true;
    script.src =
      'https://squareup.com/appointments/buyer/widget/pgvbq11g1l826u/5MV4D52P5H8ZY.js';
    this.div.appendChild(script);
  }

  render() {
    return (
      <div className="bookingWidget" ref={el => (this.div = el)}>
        <h2 style={{ color: 'black' }} className="heading-3">
          Book an Appointment
        </h2>
      </div>
    );
  }
}

export default Booking;
