import React from 'react';
import Layout from '../components/layout';
import Booking from '../components/Booking';
import HeroArrow from '../images/arrow.svg';
import HairHero from '../images/hair8.png';
import Quote from '../images/quotation.svg';
import Pics from '../images/bg-hairs-optimized.jpg';
import MessengerCustomerChat from 'react-messenger-customer-chat'

const arrowTransformStyle = {
  transform:
    'translate3d(0, 30PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
  opacity: 0,
};
const hairCollageStyle = {
  transform:
    'translate3d(0, 60px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
  opacity: 0,
};
const quoteStyle = {
  transform:
    'translate3d(0, 40PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
  opacity: 0,
};

export default () => (
  <Layout>
    <div className="section center">
      <img src={HeroArrow} alt="" className="arrow" />
      <div
        data-w-id="6b220cb1-4b9c-7fb0-75b9-53ff4a6c35a2"
        style={{ arrowTransformStyle }}
        className="hero-content"
      >
        <h1 className="heading-3">Hi. I&#x27;m Ary.</h1>
        <h3 className="hero-subhead">
          I moved to my own hair studio and I&#x27;m accepting new guests!
        </h3>
        <h3 className="hero-subhead smaller">
          Please contact me for a consultation or book an appointment below! I
          can&#x27;t wait to see you!
        </h3>
        <a href="#booking" className="button w-button">
          Book an Appointment
        </a>
      </div>
      <img
        src={HairHero}
        // srcset="images/heroHairCollage-p-500.png 500w, images/heroHairCollage.png 1075w"
        sizes="(max-width: 479px) 80vw, (max-width: 991px) 70vw, 100vw"
        data-w-id="77e69727-c455-8e07-0990-833a661c94ea"
        style={{ hairCollageStyle }}
        alt=""
        className="hero-image"
      />
    </div>
    <div className="section-3">
      <div className="div-block-26">
        <div className="div-block-30">
          <h3 className="heading-8">Contact Me</h3>
        </div>
        <div className="div-block-29">
          <ul className="list w-list-unstyled">
            <li className="list-item-2">Address</li>
            <li className="list-item">Address 2</li>
            <li>E-mail</li>
            <li>Facebook</li>
            <li>Instagram</li>
          </ul>
          <ul className="list left w-list-unstyled">
            <li>
              <a href="https://goo.gl/maps/mnEPpbaHj42vbwdZ6">
                7355 S. Buffalo Dr, Suite 1
              </a>
            </li>
            <li>
              <a href="https://goo.gl/maps/mnEPpbaHj42vbwdZ6">
                Las Vegas, NV 89148
              </a>
            </li>
            <li>
              <a href="mailto:ary.2802@gmail.com">ary.2802@gmail.com</a>
            </li>
            <li>
              <a href="https://www.facebook.com/HairByAry/">hairbyary</a>
            </li>
            <li>
              <a href="https://www.instagram.com/aryfcapra/">@AryFCapra</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="div-block-27">
        <div>
          <h3 className="heading-7">My Hours</h3>
        </div>
        <div className="div-block-28">
          <ul className="list w-list-unstyled">
            <li>Sunday</li>
            <li>Monday</li>
            <li>Tuesday</li>
            <li>Wednesday</li>
            <li>Thursday</li>
            <li>Friday</li>
            <li>Saturday</li>
          </ul>
          <ul className="list left w-list-unstyled">
            <li>Off</li>
            <li>Off</li>
            <li>10am-7pm</li>
            <li>10am-7pm</li>
            <li>10am-7pm</li>
            <li>10am-7pm</li>
            <li>9am-3pm</li>
          </ul>
        </div>
        <p className="disclaimer">
          Days and hours are subject to change. Some Mondays are available, but
          only through text. 702-576-5127
        </p>
      </div>
    </div>
    <div className="section">
      <div id="features" className="feature-wrap">
        <div id="booking">
          <Booking />
        </div>
        <div className="feature-image"></div>
      </div>
    </div>
    <div className="bottom-image-wrap">
      <img
        src={Pics}
        // srcset="images/bg-apps-p-800.jpeg 800w, images/bg-apps-p-1600.jpeg 1600w, images/bg-apps-p-2000.jpeg 2000w, images/bg-apps.jpg 2095w"
        sizes="(max-width: 2095px) 100vw, 2095px"
        alt=""
      />
    </div>
    <div id="download" className="section wide purple">
      <div
        data-animation="slide"
        data-nav-spacing="8"
        data-duration="500"
        data-infinite="1"
        data-w-id="05916fd6-a028-303c-d844-9466b0f2d927"
        style={{ quoteStyle }}
        className="quote-slider w-slider"
      >
        <div className="w-slider-mask">
          <div className="w-slide">
            <div className="quote-wrap">
              <img src={Quote} width="58" alt="" className="quote-icon" />
              <h3 className="heading-9">
                What a great experience! I had a hair crisis and Ary was able to
                see me immediately. She listened to all of my concerns and
                answered any questions I had!
              </h3>
              <div className="quote-attribution">
                <p className="paragraph">
                  <strong className="bold-text">Myra</strong>
                </p>
                <div className="quote-dash"></div>
                <p className="bold-text">Las Vegas</p>
              </div>
            </div>
          </div>
          <div className="slide w-slide">
            <div className="quote-wrap">
              <img src={Quote} width="58" alt="" className="quote-icon" />
              <h3 className="heading-12">
                Great customer service, Raw talent! I love this woman! She's
                amazing, my hair is great because of her!
              </h3>
              <div className="quote-attribution">
                <p className="paragraph">
                  <strong className="bold-text">Rachel</strong>
                </p>
                <div className="quote-dash"></div>
                <p className="bold-text">Las Vegas</p>
              </div>
            </div>
          </div>
          <div className="slide w-slide">
            <div className="quote-wrap">
              <img src={Quote} width="58" alt="" className="quote-icon" />
              <h3 className="heading-12">
                The best of the best! Always a pleasure to be around.
              </h3>
              <div className="quote-attribution">
                <p className="paragraph">
                  <strong className="bold-text">Ron</strong>
                </p>
                <div className="quote-dash"></div>
                <p className="bold-text">Las Vegas</p>
              </div>
            </div>
          </div>
          <div className="slide w-slide">
            <div className="quote-wrap">
              <img src={Quote} width="58" alt="" className="quote-icon" />
              <h3 className="heading-12">
                Ary is an amazing hair stylist. She has been doing my Brazilian
                blowouts for a while now, and every time my hair looks amazing!
                She is very professional.
              </h3>
              <div className="quote-attribution">
                <p className="paragraph">
                  <strong className="bold-text">Laura</strong>
                </p>
                <div className="quote-dash"></div>
                <p className="bold-text">Las Vegas</p>
              </div>
            </div>
          </div>
        </div>
        <div className="left-arrow w-slider-arrow-left">
          <div className="slider-arrow w-icon-slider-left"></div>
        </div>
        <div className="right-arrow w-slider-arrow-right">
          <div className="slider-arrow w-icon-slider-right"></div>
        </div>
        <div className="slide-nav w-slider-nav w-slider-nav-invert w-round"></div>
      </div>
    </div>
    <div className="section-3">
      <div className="div-block-26 round-2">
        <div className="div-block-30">
          <h2 className="heading-3">Take a 360&deg; tour!</h2>
        </div>
      </div>
      <div className="div-block-27 round-2">
        <div className="div-block-28">
          <iframe className="tour-360" width="100%" height="456" allowFullScreen="true" allow="accelerometer; magnetometer; gyroscope" src="https://panoraven.com/en/embed/5MbBMuFbsP"></iframe>
        </div>
      </div>
    </div>
    <div className="section wide princess">
      <div>
        <h2 className="heading-11">Thanks for stopping by!</h2>
        <p className="paragraph-2">See you at the salon!</p>
      </div>
    </div>
    <MessengerCustomerChat
      pageId="1187979311212653"
      appId="216502067105944"
    />
  </Layout>
);


