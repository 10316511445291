import React from 'react';
import IconFB from './IconFB';
import IconIG from './IconIG';
import IconEmail from './IconEmail';
import IconMap from './IconMap';

const Footer = () => (
  <div className="footer">
    <div className="wrap">
      <div className="footer-list">
        <a href="mailto:ary.2802@gmail.com" className="footer-link">
          <IconEmail iconTitle="Email Icon" iconColor="white" />
        </a>
        <a href="https://www.facebook.com/HairByAry/" className="footer-link">
          <IconFB iconTitle="Facebook Icon" iconColor="white" />
        </a>
        <a href="https://www.instagram.com/aryfcapra/" className="footer-link">
          <IconIG iconTitle="Instagram Icon" iconColor="white" />
        </a>
        <a href="https://goo.gl/maps/mnEPpbaHj42vbwdZ6" className="footer-link">
          <IconMap iconTitle="Map Marker Icon" iconColor="white" />
        </a>
      </div>
      <div className="footer-list bolder">
        Copyright &copy; 2019 &bull; Hair by Ary
        <br />
        An &quot;It&#x27;s My Site But You Can Surf It&quot; production.
      </div>
    </div>
  </div>
);

export default Footer;
